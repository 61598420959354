import Logo from '../../components/logo/logo';
import Text from '../../components/text/text';
import styles from './footer.module.scss';

const Footer: React.FC = () => {
  return (
    <footer className={styles.footer}>
      <Logo asPictogram />
      <ul>
        <li className="mb-xs">
          <Text variant="lg" color="dark">
            <a href="mailto:lovelocks.nft@gmail.com">Contact</a>
          </Text>
        </li>
        <li>
          <Text variant="lg" color="dark">
            <a
              href="https://twitter.com/LovelocksNFT"
              target="_blank"
              rel="noreferrer"
            >
              Twitter
            </a>
          </Text>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
