import { useState } from 'react';
import { FormEvent, useCallback } from 'react';

import Button from '../../../../components/button/button';
import FieldInput from '../../../../components/fieldInput/fieldInput';
import Text from '../../../../components/text/text';

import styles from './more.module.scss';

const More: React.FC = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const encodeFormData = useCallback((data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      )
      .join('&');
  }, []);

  const onSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      setIsLoading(true);

      try {
        const options = {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encodeFormData({
            'form-name': event.currentTarget.getAttribute('name'),
            email,
          }),
        };

        await fetch('/', options);

        setIsSuccess(true);
      } catch (error) {
        setIsSuccess(false);

        throw new Error('Failed to submit email address');
      } finally {
        setIsLoading(false);
      }
    },
    [email, encodeFormData]
  );
  return (
    <section className={styles.more}>
      <header>
        <Text
          tag="h2"
          color="dark"
          variant="headline"
          lineheight="xxl"
          align="center"
        >
          Want to know more ?
        </Text>
      </header>
      <Text
        tag="p"
        variant="subtitle"
        lineheight="lg"
        align="center"
        className="mb-lg"
        color="dark"
      >
        As Web 3 enthusiasts, we launch this project with no more objective to
        give you a unique way to express you feelings to the one you love.
        Lovelock, we hope, is the first but not the last Web3 and Nft project we
        work on. If you wish to know more about it, make sure to follow us on
        Twitter !
      </Text>

      {/* {isSuccess ? (
        <Text
          tag="p"
          variant="subtitle"
          lineheight="lg"
          align="center"
          className="mt-lg"
          color="love"
        >
          You are now registred !
        </Text>
      ) : (
        <form
          method="POST"
          data-netlify="true"
          name="contact"
          className={styles.getNotified}
        >
          <FieldInput
            type="email"
            onInput={(event) => setEmail(event.currentTarget.value)}
            label="Mail address"
          />
          <Button isLoading={isLoading} type="submit">
            I want to be involved
          </Button>
        </form>
      )} */}
    </section>
  );
};

export default More;
