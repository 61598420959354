import {
  IPFS_GATEWAY_API_URL,
  STORAGE_API_UPLOAD_ENDPOINT,
  STORAGE_API_URL,
} from '../constants';

// process.env.STORAGE_API_KEY
const DEV_API_KEY =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDI1NWQxQTlBNkE4ZTA0QjgxMzNCNzJGMzY3NTI4MTRCNjNDRjcxOTYiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY0NDE3MzA5MzcxNiwibmFtZSI6ImRldm5ldCJ9.Jjg7sgE4XPjYs9KJCyIqb5GEariVutPYi1OsWQFZIpk';

interface StorageFile {
  cid: string;
}

const useMediaStorage = () => {
  const uploadToStorage = async (file: Blob): Promise<StorageFile> => {
    try {
      const storageUrl = `${STORAGE_API_URL}${STORAGE_API_UPLOAD_ENDPOINT}`;
      const options = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${DEV_API_KEY}`,
        },
        body: file,
      };
      const { value }: { value: StorageFile } = await (
        await fetch(storageUrl, { ...options, mode: 'cors' })
      ).json();

      return value;
    } catch (error) {
      throw new Error('Upload to storage failed');
    }
  };

  const getFileFromStorage = async ({ cid }: StorageFile): Promise<Buffer> => {
    try {
      // const url = `${STORAGE_API_URL}/${cid}`;
      // const options = {
      //   method: 'GET',
      //   headers: {
      //     Authorization: `Bearer ${DEV_API_KEY}`,
      //   },
      // };
      // const data = await (await fetch(url, options)).json();
      const ipfsUrl = `${IPFS_GATEWAY_API_URL}/${cid}`;
      const encryptedFile = await (
        await fetch(ipfsUrl, { method: 'GET' })
      ).json();

      return Buffer.from(JSON.stringify(encryptedFile));
    } catch (error) {
      throw new Error('Get file from storage failed');
    }
  };

  return {
    uploadToStorage,
    getFileFromStorage,
  };
};

export default useMediaStorage;
export type { StorageFile };
