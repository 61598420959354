import foregroundImage from '../../assets/images/foreground.png';
// import foregroundLogo from '../../assets/images/logo-foreground.png';
import loveForeground from '../../assets/images/love-foreground.png';
import locksForeground from '../../assets/images/locks-foreground.png';

import styles from './foreground.module.scss';

const ForegroundImage: React.FC = () => {
  return (
    <>
      <img src={foregroundImage} className={styles.plants} alt="Background" />
      <img src={loveForeground} className={styles.loveLogo} alt="Background" />
      <img
        className={styles.locksLogo}
        src={locksForeground}
        alt="Background"
      />
    </>
  );
};

export default ForegroundImage;
