import { useState, useEffect, useCallback } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';

import Text from '../../../../components/text/text';
import Button from '../../../../components/button/button';
import BackgroundImage from '../../../../components/backgroundImage/backgroundImage';

// import useLockedStore from '../../../../store/mint';
// import useMediaStorage, {
//   StorageFile,
// } from '../../../../modules/useMediaStorage';
// import useFileEncryption from '../../../../modules/useEncryptFile';

import styles from './locked.module.scss';
import useMint from '../../../../modules/useMint';

const Locked: React.FC = () => {
  const { id: txId } = useParams();
  const { subscribeToTxStatus } = useMint();

  const onStatusUpdate = useCallback((data) => {}, []);

  useEffect(() => {
    subscribeToTxStatus(txId ?? '', onStatusUpdate);
  }, [onStatusUpdate, subscribeToTxStatus, txId]);
  // const { getFileFromStorage } = useMediaStorage();
  // const { decryptFile } = useFileEncryption();
  // const [files, setFiles] = useState<string[]>([]);

  // const ok = useCallback(async () => {
  //   const file = await getFileFromStorage({
  //     cid: 'bafkreidtyqiicrsfwn2rphrw5eqs57hoxbzpt7m6cy72rafwlvl42t4al4',
  //   });
  //   const decryptedFile = await decryptFile(file);
  //   const plainFile = new Blob([decryptedFile], { type: 'image/png' });
  //   setFiles([URL.createObjectURL(plainFile)]);
  // }, []);

  // useEffect(() => {
  //   ok();
  // }, [ok]);

  return (
    <div className={styles.locked}>
      <header className={styles.header}>
        <Text
          tag="h1"
          color="dark"
          weight="semibold"
          variant="headline"
          lineheight="xxl"
        >
          Yay ! Here's your lock :
        </Text>
        <Text
          tag="p"
          color="dark"
          variant="subtitle"
          lineheight="lg"
          className="mt-md"
          align="center"
        >
          In a close future, your lock will be displayed on a generative grid
          along other LoveLockers !
        </Text>
      </header>

      <Text
        color="dark"
        variant="subtitle"
        weight="medium"
        className={styles.tx}
      >
        You can view your transaction here:
        <Text tag="span">
          <a
            href={`https://explorer.stacks.co/txid/${txId}?chain=mainnet`}
            target="_blank"
            rel="noreferrer"
          >
            {`https://explorer.stacks.co/txid/${txId}?chain=mainnet`}
          </a>
        </Text>
      </Text>

      <footer className={styles.footer}>
        <Link to="/">
          <Button>Home</Button>
        </Link>
      </footer>

      <BackgroundImage />
    </div>
  );
};

export default Locked;
