import React from 'react';

import useAuthentication from '../../../../modules/useAuthentication';

import Button from '../../../../components/button/button';

const ConnectButton: React.FC = () => {
  const { isUserSignedIn, authenticate, logout } = useAuthentication();

  return isUserSignedIn ? (
    <Button onClick={logout} type="button">
      Logout
    </Button>
  ) : (
    <Button onClick={authenticate} type="button">
      Connect wallet
    </Button>
  );
};

export default ConnectButton;
