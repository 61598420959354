import classNames from 'classnames';
import React, { ButtonHTMLAttributes } from 'react';

import Text from '../text/text';
import styles from './button.module.scss';

type Variant = 'solid' | 'ghost' | 'outline';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
  variant?: Variant;
}

const Button: React.FC<ButtonProps> = ({
  children,
  isLoading = false,
  variant = 'solid',
  ...props
}) => {
  const buttonClass = classNames({
    [styles.button]: true,
    [styles[`button-${variant}`]]: true,
    [styles.buttonLoading]: isLoading,
  });

  return (
    <button className={buttonClass} disabled={isLoading} {...props}>
      {isLoading ? (
        <div className={styles.loader}>
          <svg className={styles.circular} viewBox="25 25 50 50">
            <circle
              className={styles.path}
              cx="50"
              cy="50"
              r="20"
              fill="none"
              strokeWidth={4}
              strokeMiterlimit={10}
            />
          </svg>
        </div>
      ) : null}
      <Text
        tag="span"
        weight="semibold"
        variant="lg"
        color={variant !== 'solid' ? 'love' : 'white'}
        lineheight="lg"
      >
        {children}
      </Text>
    </button>
  );
};

export default Button;
