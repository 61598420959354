import classNames from 'classnames';
import { HTMLAttributes } from 'react';

import styles from './text.module.scss';

type Tag =
  | 'label'
  | 'a'
  | 'p'
  | 'span'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6';

type Variant = 'headline' | 'title' | 'subtitle' | 'xs' | 'sm' | 'md' | 'lg';

type Color = 'white' | 'love' | 'blue-light' | 'dark' | 'gray';

type Lineheight = 'xs' | 'sm' | 'base' | 'md' | 'lg' | 'xl' | 'xxl';

type Alignement = 'left' | 'center' | 'right';

type FontFamily = 'primary' | 'secondary';

type Weight =
  | 'thin'
  | 'light'
  | 'normal'
  | 'medium'
  | 'semibold'
  | 'bold'
  | 'extrabold'
  | 'ultra';

interface TextProps extends HTMLAttributes<HTMLElement> {
  align?: Alignement;
  color?: Color;
  family?: FontFamily;
  lineheight?: Lineheight;
  tag?: Tag;
  variant?: Variant;
  weight?: Weight;
  htmlFor?: string;
}

const Text: React.FC<TextProps> = ({
  align = 'left',
  children,
  className = '',
  color = 'white',
  family = 'primary',
  htmlFor,
  tag = 'p',
  lineheight = 'base',
  variant = 'md',
  weight = 'normal',
}) => {
  const Component = tag;
  const textClass = classNames({
    ...(className ? { [className]: true } : {}),
    [styles[`color-${color}`]]: true,
    [styles[`lineheight-${lineheight}`]]: true,
    [styles[`variant-${variant}`]]: true,
    [styles[`weight-${weight}`]]: true,
    [styles[`align-${align}`]]: true,
    [styles[`family-${family}`]]: true,
  });

  return (
    <Component htmlFor={htmlFor} className={textClass}>
      {children}
    </Component>
  );
};

export default Text;
