import { useState } from 'react';
import {
  ContractCallRegularOptions,
  FinishedTxData,
  openContractCall,
} from '@stacks/connect';
import { StacksMainnet, StacksMocknet } from '@stacks/network';
import {
  falseCV,
  stringAsciiCV,
  trueCV,
  listCV,
  stringUtf8CV,
  uintCV,
  PostConditionMode,
} from '@stacks/transactions';
import { connectWebSocketClient } from '@stacks/blockchain-api-client';

import { MintInfos } from '../store/mint/index';

import useFileEncryption from './useEncryptFile';
import useMediaStorage from './useMediaStorage';

const useMint = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { encryptFile } = useFileEncryption();
  const { uploadToStorage } = useMediaStorage();

  const subscribeToTxStatus = async (txId: string, onUpdate: Function) => {
    const client = await connectWebSocketClient(
      // process.env.apiUrl
      // 'wss://stacks-node-api.testnet.stacks.co/'
      'wss://stacks-node-api.mainnet.stacks.co/'
    );

    await client.subscribeTxUpdates(txId, (event) => {
      onUpdate(event);
    });
  };

  const mint = async (mintInfos: MintInfos, onFinish: Function) => {
    setIsLoading(true);

    try {
      const file = new File(
        [await (await fetch(mintInfos.file?.localURL ?? '')).blob()],
        'file',
        { type: mintInfos.file?.mime }
      );

      const encryptedFileAsText = await encryptFile(file);
      const encryptedFile = new Blob([encryptedFileAsText], {
        type: 'text/plain',
      });
      const { cid } = await uploadToStorage(encryptedFile);

      const functionArgs = [
        listCV([mintInfos.nameOne, mintInfos.nameTwo].map(stringUtf8CV)),
        stringUtf8CV(mintInfos.quote ?? ''),
        stringAsciiCV(cid),
      ];
      const txOptions: ContractCallRegularOptions = {
        // process.env.contract
        contractAddress: 'SP6GFGCTRNFSSC7GCXKCCW7TMZ8NAN6T2MZE3M23',
        // contractAddress: 'ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM',
        // contractName: 'robust-aqua-catshark',
        contractName: 'lovelocks',
        functionName: 'mint-lovelock-and-claim',
        functionArgs,
        postConditionMode: PostConditionMode.Allow,
        // process.env.network
        // network: new StacksMocknet(),
        network: new StacksMainnet(),
        onCancel: () => {
          throw new Error('Canceled mint');
        },
        onFinish: (data: FinishedTxData) => onFinish(data),
        // : async (data: FinishedTxData) => {
        //   await subscribeToTxStatus(data.txId, onTxStatusUpdate);
        //   console.log('Stacks Transaction:', data.stacksTransaction);
        //   console.log('Transaction ID:', data.txId);
        //   console.log('Raw transaction:', data.txRaw);
        // },
      };

      await openContractCall(txOptions);
    } catch (error) {
      throw new Error('Minting failed');
    } finally {
      setIsLoading(false);
    }
  };

  const setSaleStatus = async (isOpen: boolean) => {
    try {
      const functionArgs = [isOpen ? trueCV() : falseCV()];
      const txOptions = {
        // process.env.contract
        contractAddress: 'ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM',
        contractName: 'lovelocks',
        functionName: 'set-sale-open',
        functionArgs,
        // process.env.network
        network: new StacksMocknet(),
        appDetails: {
          name: 'Lovelocks',
          icon: window.location.origin + '/favicon.svg',
        },
        onFinish: (data: any) => {
          console.log('Stacks Transaction:', data.stacksTransaction);
          console.log('Transaction ID:', data.txId);
          console.log('Raw transaction:', data.txRaw);
        },
      };

      await openContractCall(txOptions);
    } catch (error) {
      throw new Error('Setting sale status');
    }
  };

  const setMaxMints = async (maxMints: number) => {
    try {
      const functionArgs = [uintCV(maxMints)];
      const txOptions = {
        // process.env.contract
        contractAddress: 'ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM',
        contractName: 'lovelocks',
        functionName: 'set-max-mints',
        functionArgs,
        // process.env.network
        network: new StacksMocknet(),
        appDetails: {
          name: 'Lovelocks',
          icon: window.location.origin + '/favicon.svg',
        },
        onFinish: (data: any) => {
          console.log('Stacks Transaction:', data.stacksTransaction);
          console.log('Transaction ID:', data.txId);
          console.log('Raw transaction:', data.txRaw);
        },
      };

      await openContractCall(txOptions);
    } catch (error) {
      throw new Error('Setting sale status');
    }
  };

  const setCanMintAndClaim = async (canMintAndClaim: boolean) => {
    try {
      const functionArgs = [canMintAndClaim ? trueCV() : falseCV()];
      const txOptions = {
        // process.env.contract
        contractAddress: 'ST1PQHQKV0RJXZFY1DGX8MNSNYVE3VGZJSRTPGZGM',
        contractName: 'lovelocks',
        functionName: 'set-can-mint-and-claim',
        functionArgs,
        // process.env.network
        network: new StacksMocknet(),
        appDetails: {
          name: 'Lovelocks',
          icon: window.location.origin + '/favicon.svg',
        },
        onFinish: (data: any) => {
          console.log('Stacks Transaction:', data.stacksTransaction);
          console.log('Transaction ID:', data.txId);
          console.log('Raw transaction:', data.txRaw);
        },
      };

      await openContractCall(txOptions);
    } catch (error) {
      throw new Error('Setting sale status');
    }
  };

  return {
    isLoading,
    mint,
    setSaleStatus,
    setMaxMints,
    setCanMintAndClaim,
    subscribeToTxStatus,
  };
};

export default useMint;
