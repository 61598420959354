import { useEffect, FormEvent, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Text from '../../../../components/text/text';
import Button from '../../../../components/button/button';
import FileUploader from '../../../../components/fileUploader/fileUploader';
import BackgroundImage from '../../../../components/backgroundImage/backgroundImage';

import useMintStore from '../../../../store/mint';

import styles from './file.module.scss';

const File: React.FC = () => {
  const navigate = useNavigate();
  const { mintInfos, setMintInfos } = useMintStore();

  const getMediaDisplay = useCallback(() => {
    const { mime, localURL } = mintInfos.file ?? {};
    const isImage = mime?.includes('image');
    const isAudio = mime?.includes('audio');
    const isVideo = mime?.includes('video');

    if (isImage)
      return <img className={styles.media} src={localURL} alt="User file" />;
    if (isVideo)
      return <video className={styles.media} src={localURL} controls />;
    if (isAudio)
      return <audio className={styles.media} src={localURL} controls />;
  }, [mintInfos]);

  const onUploadSuccess = useCallback(
    async (file: File) => {
      if (mintInfos.file?.localURL) {
        URL.revokeObjectURL(mintInfos.file?.localURL);
      }

      setMintInfos({
        ...mintInfos,
        file: {
          localURL: URL.createObjectURL(file),
          mime: file.type,
          name: file.name,
        },
      });
    },
    [mintInfos, setMintInfos]
  );

  const onSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      navigate('/mint');
    },
    [navigate]
  );

  useEffect(() => {
    if (mintInfos.file?.localURL) {
      URL.revokeObjectURL(mintInfos.file?.localURL);
      setMintInfos({ ...mintInfos, file: null });
    }
  }, []);

  return (
    <form onSubmit={onSubmit} className={styles.file}>
      <header className={styles.header}>
        <h1 className={styles.heading}>
          <Text
            tag="span"
            weight="semibold"
            color="dark"
            variant="headline"
            lineheight="xxl"
          >
            Add your unique private file
          </Text>
        </h1>
        <Text
          tag="p"
          color="dark"
          variant="subtitle"
          lineheight="lg"
          className="mt-md"
          align="center"
        >
          Love lock is unalterable, create your lock and you will have the
          certainty to keep engraved forever the union with the person you love,
          Each lock gives the possibility to store 2 names and a document, think
          carefully because it can not be modified once created.
        </Text>
      </header>

      {mintInfos.file?.localURL ? (
        <div className={styles.filePreview}>{getMediaDisplay()}</div>
      ) : null}

      <FileUploader
        onUploadSuccess={onUploadSuccess}
        onUploadFailure={() => {}}
      />

      <footer className={styles.footer}>
        <Link to="/quote" className="mr-sm">
          <Button variant="ghost">Back</Button>
        </Link>
        <Button>Continue</Button>
      </footer>

      <BackgroundImage />
    </form>
  );
};

export default File;
