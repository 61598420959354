import { Routes, Route } from 'react-router-dom';

import Toaster from './components/toaster/toaster';
import Layout from './components/layout/layout';
import ForegroundImage from './components/foreground/foreground';
import Header from './features/header/components/header/header';
import Landing from './features/landing/page/landing';
import Footer from './features/footer/footer';
import Quote from './features/mint/page/quote/quote';
import File from './features/mint/page/file/file';
import Mint from './features/mint/page/mint/mint';
import Locked from './features/mint/page/locked/locked';

import './styles/main.scss';

const App = () => {
  return (
    <>
      <Header />

      <Layout>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/quote" element={<Quote />} />
          <Route path="/file" element={<File />} />
          <Route path="/mint" element={<Mint />} />
          <Route path="/locked/:id" element={<Locked />} />
          <Route path="/grid" element={<Landing />} />
          <Route path="*" element={<Landing />} />
        </Routes>
      </Layout>

      <Footer />

      <ForegroundImage />

      <Toaster />
    </>
  );
};

export default App;
