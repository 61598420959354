import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

interface File {
  name: string;
  mime: string;
  localURL: string;
}

interface MintInfos {
  nameOne: string;
  nameTwo: string;
  quote?: string;
  file: null | File;
}

const getInitialState = (): MintInfos => ({
  nameOne: '',
  nameTwo: '',
  quote: '',
  file: null,
});

const mintState = atomWithStorage<MintInfos>('mint', getInitialState());

const useMintStore = () => {
  const [mintInfos, setMintInfos] = useAtom(mintState);

  return {
    mintInfos,
    setMintInfos,
  };
};

export default useMintStore;
export { getInitialState };
export type { MintInfos };
