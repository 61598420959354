import classNames from 'classnames';

import styles from './layout.module.scss';

const Layout: React.FC = ({ children }) => {
  const className = classNames({
    [styles.main]: true,
    [styles.mainLanding]: window.location.pathname === '/',
    [styles.mainLock]: window.location.pathname === '/quote',
    [styles.mainLock]: window.location.pathname === '/file',
    [styles.mainLock]: window.location.pathname === '/mint',
    [styles.mainLock]: window.location.pathname === '/locked',
  });

  return <main className={className}>{children}</main>;
};

export default Layout;
