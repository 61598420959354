import backgroundImage from '../../assets/images/landing-background.png';

import styles from './backgroundImage.module.scss';

const BackgroundImage: React.FC = () => {
  return (
    <img src={backgroundImage} className={styles.background} alt="Background" />
  );
};

export default BackgroundImage;
