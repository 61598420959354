import BackgroundGrid from '../components/background/background';
import Concept from '../components/concept/concept';
import Grid from '../components/grid/grid';
import Hero from '../components/hero/hero';
import More from '../components/more/more';
import Perks from '../components/perks/perks';
import Steps from '../components/steps/steps';
import BackgroundImage from '../../../components/backgroundImage/backgroundImage';

const Landing: React.FC = () => {
  return (
    <>
      <Hero />
      <Concept />
      <Perks />
      <Steps />
      <Grid />
      <More />
      <BackgroundGrid />
      <BackgroundImage />
    </>
  );
};

export default Landing;
