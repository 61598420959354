import classNames from 'classnames';

import useErrors from '../../modules/useErrors';

import Text from '../text/text';

import styles from './toaster.module.scss';

const Toaster: React.FC = () => {
  const { errors = [] } = useErrors();
  const toasterClass = classNames({
    [styles.toaster]: true,
    [styles.toasterActive]: Boolean(errors.length),
  });

  return (
    <article className={toasterClass}>
      <ul className={styles.toastList}>
        {errors.map((error) => (
          <li key={`error-${error.id}`} className={styles.toast}>
            <Text color="white" weight="semibold" lineheight="md">
              {error.message}
            </Text>
          </li>
        ))}
      </ul>
    </article>
  );
};

export default Toaster;
