import useMint from '../../../../modules/useMint';

import Logo from '../../../../components/logo/logo';
import ConnectButton from '../../../connect/components/connectButton/connectButton';

import styles from './header.module.scss';

const Header: React.FC = () => {
  const { setSaleStatus, setMaxMints, setCanMintAndClaim } = useMint();
  return (
    <header className={styles.header}>
      {/* <Logo /> */}
      <ConnectButton />
      {/* <button style={{ color: 'red' }} onClick={() => setSaleStatus(true)}>
        Open sale
      </button>
      <button style={{ color: 'red' }} onClick={() => setMaxMints(16)}>
        Set max mints to 16
      </button>
      <button style={{ color: 'red' }} onClick={() => setCanMintAndClaim(true)}>
        Set can mint and claim
      </button> */}
    </header>
  );
};

export default Header;
