import Button from '../../../../components/button/button';
import Text from '../../../../components/text/text';

import styles from './steps.module.scss';

interface Step {
  title: string;
  text: string;
}

const Steps: React.FC = () => {
  const steps: Step[] = [
    {
      title: '1',
      text: 'Choose the two names you want to be linked. Add, if you want, a short quote (140 characters maximum)',
    },
    {
      title: '2',
      text: 'Choose a unique private file to insert into your lock. This file will be kept secret from everyone else',
    },
    {
      title: '3',
      text: 'Each lock is unique and limited on the grid, which can only accounts 1728 slots after all drops',
    },
  ];

  return (
    <section className={styles.steps}>
      <header className={styles.header}>
        <Text
          tag="h2"
          color="dark"
          variant="headline"
          lineheight="xxl"
          align="center"
        >
          The lock
        </Text>
        <Text
          tag="p"
          variant="subtitle"
          lineheight="lg"
          align="center"
          className="mb-lg"
          color="dark"
        >
          LoveLock is an NFT project which allows lovers and friend to create a
          clear and durable expression of their relationship and ensures that it
          will be forever part of an unique grid.
        </Text>
      </header>

      <ul className={styles.stepsList}>
        {steps.map((step, index) => (
          <li key={`step-${index}`} className={styles.stepsItem}>
            <Text
              tag="h3"
              variant="headline"
              weight="medium"
              lineheight="lg"
              align="center"
              color="dark"
              className="mb-md"
            >
              {step.title}
            </Text>
            <Text
              tag="p"
              variant="subtitle"
              weight="light"
              lineheight="lg"
              align="center"
              className="mb-lg"
              color="dark"
            >
              {step.text}
            </Text>
          </li>
        ))}
      </ul>

      <footer>
        <Text
          tag="p"
          variant="title"
          weight="medium"
          lineheight="xl"
          align="center"
          className="mx-xxl mb-xl"
          color="dark"
        >
          The lock is now part of the grid, the names and the quote are engraved
          forever and visible
        </Text>
        <Button
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          type="button"
        >
          Lock my love
        </Button>
      </footer>
    </section>
  );
};

export default Steps;
