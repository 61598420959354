import { useState } from 'react';
import { AppConfig, UserSession, showConnect } from '@stacks/connect';

const appConfig = new AppConfig();
const userSession = new UserSession({ appConfig });

const useAuthentication = () => {
  const [isUserSignedIn, setIsUserSignedIn] = useState(
    userSession.isUserSignedIn()
  );

  const authenticate = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      showConnect({
        appDetails: {
          name: 'Lovelocks',
          icon: window.location.origin + '/favicon.png',
        },
        redirectTo: '/',
        onCancel: () => reject(),
        onFinish: () => resolve(setIsUserSignedIn(true)),
        userSession,
      });
    });
  };

  const logout = () => {
    userSession.signUserOut();
    setIsUserSignedIn(false);
  };

  window.onload = () => {
    if (userSession.isSignInPending()) {
      userSession.handlePendingSignIn().then(() => {
        setIsUserSignedIn(true);
      });
    } else if (userSession.isUserSignedIn()) {
      setIsUserSignedIn(true);
    }
  };

  return {
    isUserSignedIn,
    authenticate,
    logout,
    userSession,
  };
};

export default useAuthentication;
