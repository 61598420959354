import Button from '../../../../components/button/button';
import Text from '../../../../components/text/text';

import styles from './grid.module.scss';

const Grid: React.FC = () => {
  return (
    <section className={styles.grid}>
      <header className="mb-lg">
        <Text
          tag="h2"
          color="dark"
          variant="headline"
          lineheight="xxl"
          align="center"
        >
          The grid
        </Text>
      </header>
      <Text
        tag="p"
        variant="subtitle"
        lineheight="lg"
        align="center"
        className="mb-lg"
        color="dark"
      >
        We created this environnment inspired by the necessity to create a place
        where feelings can have a bright expression. As every relationship is
        specific, every 1728 slots of the grid is unique. explore it and
        discover the beautiful stories on what it is built on.
      </Text>
      <Button>Explore the grid (coming soon)</Button>
    </section>
  );
};

export default Grid;
