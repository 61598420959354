import { Link } from 'react-router-dom';

import { ReactComponent as SVGLogo } from '../../assets/vectors/logo.svg';
import { ReactComponent as SVGPicto } from '../../assets/vectors/picto.svg';

import styles from './logo.module.scss';

interface LogoProps {
  asPictogram?: boolean;
}

const Logo: React.FC<LogoProps> = ({ asPictogram = false }) => {
  return (
    <Link to="/" className={styles.wrapper}>
      {asPictogram ? (
        <SVGPicto className={styles.logoPicto} />
      ) : (
        <SVGLogo className={styles.logoFull} />
      )}
    </Link>
  );
};

export default Logo;
