import { useState, useEffect } from 'react';

type MessageType = 'success' | 'warning' | 'error';

interface AppMessage {
  type: MessageType;
  message: string;
  id: number;
  delay: number;
}

const useErrors = () => {
  const [errors, setErrors] = useState<AppMessage[]>([]);
  const defaultDelay = 5000;

  const addErrors = ({
    type,
    message,
    delay = defaultDelay,
  }: Omit<AppMessage, 'id'>) => {
    const appMessage: AppMessage = {
      type,
      message,
      id: Date.now(),
      delay,
    };

    setErrors([...errors, appMessage]);
  };

  useEffect(() => {
    if (!errors.length) return;

    const { delay, id } = errors.at(-1) as AppMessage;
    const timeoutId = setTimeout(() => {
      setErrors(errors.filter((error) => error.id !== id));
    }, delay);

    return () => clearTimeout(timeoutId);
  }, [errors]);

  return {
    errors,
    addErrors,
  };
};

export default useErrors;
export type { AppMessage };
