import backgroundImage from '../../../../assets/images/grid-background.png';

import styles from './background.module.scss';

const BackgroundGrid: React.FC = () => {
  return (
    <div className={styles.background}>
      <img
        src={backgroundImage}
        alt="background-grid"
        className={styles.grid}
      />
      {/* <GridTracing /> */}
    </div>
  );
};

export default BackgroundGrid;
