import Text from '../../../../components/text/text';

import styles from './perks.module.scss';

interface Perk {
  title: string;
  text: string;
}

const Perks: React.FC = () => {
  const perks: Perk[] = [
    {
      title: 'Securised',
      text: 'Based on latest blockchains technical procurement, you will always be the only owner of your lock',
    },
    {
      title: 'Private',
      text: 'We only ask the information needed to create you lock, we’ll never borrow about anything ',
    },
    {
      title: 'Unique',
      text: 'Each lock is unique and limited on our grid which only accounts 1728 slots ',
    },
  ];

  return (
    <section className={styles.perks}>
      <header className={styles.header}>
        <Text
          tag="h2"
          color="dark"
          variant="headline"
          lineheight="xxl"
          align="center"
        >
          The only Lock that will last forever.
        </Text>
        <Text
          tag="p"
          variant="subtitle"
          lineheight="lg"
          align="center"
          color="dark"
        >
          LoveLock is an NFT project which allows lovers and friend to create a
          clear and durable expression of their relationship and ensures that it
          will be forever part of an unique grid.
        </Text>
      </header>

      <ul className={styles.perksList}>
        {perks.map((step, index) => (
          <li key={`step-${index}`} className={styles.perksItem}>
            <Text
              tag="h3"
              variant="title"
              weight="medium"
              lineheight="lg"
              align="center"
              color="dark"
            >
              {step.title}
            </Text>
            <Text
              tag="p"
              variant="subtitle"
              weight="light"
              lineheight="lg"
              align="center"
              className="mb-lg"
              color="dark"
            >
              {step.text}
            </Text>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default Perks;
