import {
  ChangeEvent,
  DragEvent,
  InputHTMLAttributes,
  useCallback,
  useState,
} from 'react';
import classNames from 'classnames';

import { MAX_FILE_SIZE_MEGAOCTETS } from '../../constants';

import Text from '../text/text';

import styles from './fileUploader.module.scss';

interface FileUploaderProps extends InputHTMLAttributes<HTMLInputElement> {
  onUploadSuccess: (file: File) => void;
  onUploadFailure: () => void;
}

const FileUploader: React.FC<FileUploaderProps> = ({
  onUploadFailure,
  onUploadSuccess,
  id,
}) => {
  const [isDraggedOver, setDragOver] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [error, setError] = useState('');
  const uploadWrapperClass = classNames({
    [styles.uploadWrapper]: true,
    [styles.uploadWrapperActive]: isDraggedOver,
  });

  const verifyFileSize = useCallback((file: File) => {
    const fileSize = Math.ceil(file.size / 1000000);
    const isFileTooLarge = fileSize > MAX_FILE_SIZE_MEGAOCTETS;

    setError(
      isFileTooLarge
        ? 'Your file is too large, the maximum file size is 10MB'
        : ''
    );

    return isFileTooLarge;
  }, []);

  const onUpload = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.currentTarget.files?.item(0) as File;

      if (verifyFileSize(file)) return onUploadFailure();

      setUploadedFile(file);
      onUploadSuccess(file);
    },
    [onUploadFailure, onUploadSuccess, verifyFileSize]
  );

  const onDrop = useCallback(
    (event: DragEvent) => {
      event.preventDefault();
      setDragOver(false);
      setUploadedFile(null);

      const [file] = event.dataTransfer.files;

      verifyFileSize(file);
      setUploadedFile(file);
      onUploadSuccess(file);
    },
    [onUploadSuccess, verifyFileSize]
  );

  const onDragOver = useCallback(
    (event: DragEvent) => {
      event.preventDefault();
      setError('');
      setDragOver(true);
    },
    [setDragOver]
  );

  return (
    <div className={styles.wrapper}>
      <div
        onDragOver={onDragOver}
        onDragEnter={onDragOver}
        onDragEnd={() => setDragOver(false)}
        onDragLeave={() => setDragOver(false)}
        onDrop={onDrop}
        className={uploadWrapperClass}
      >
        <label htmlFor={id} className={styles.input}>
          <input
            onChange={onUpload}
            type="file"
            name=""
            id={id}
            accept="image/*, video/*, audio/*"
            required={!uploadedFile}
          />
          {uploadedFile?.name || 'Drop or click to upload your file'}
        </label>
      </div>
      {error ? (
        <Text color="love" lineheight="xl">
          {error}
        </Text>
      ) : null}
    </div>
  );
};

export default FileUploader;
