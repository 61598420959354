const MAX_QUOTE_CHARACTERS = 140;
const MAX_NAME_CHARACTERS = 20;
const MAX_FILE_SIZE_MEGAOCTETS = 10;
const STORAGE_API_URL = 'https://api.nft.storage/';
const STORAGE_API_UPLOAD_ENDPOINT = 'upload';
const IPFS_GATEWAY_API_URL = 'https://ipfs.io/ipfs';

export {
  MAX_NAME_CHARACTERS,
  MAX_QUOTE_CHARACTERS,
  MAX_FILE_SIZE_MEGAOCTETS,
  STORAGE_API_URL,
  STORAGE_API_UPLOAD_ENDPOINT,
  IPFS_GATEWAY_API_URL,
};
