import React, { ChangeEventHandler, InputHTMLAttributes } from 'react';

import Text from '../text/text';

import styles from './fieldInput.module.scss';

interface FieldInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  onInput: ChangeEventHandler<HTMLInputElement>;
}

const FieldInput: React.FC<FieldInputProps> = ({
  label,
  id,
  onInput,
  ...props
}) => {
  return (
    <div className={styles.wrapper}>
      <Text tag="label" variant="lg" color="dark" align="center" htmlFor={id}>
        {label}
      </Text>
      <input
        onInput={onInput}
        className={styles.input}
        type="text"
        id={id}
        {...props}
      />
    </div>
  );
};

export default FieldInput;
