import { FormEvent, useCallback, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FinishedTxData } from '@stacks/connect';
import classNames from 'classnames';

import Text from '../../../../components/text/text';
import Button from '../../../../components/button/button';
import BackgroundImage from '../../../../components/backgroundImage/backgroundImage';

import useMintStore from '../../../../store/mint';

import useMint from '../../../../modules/useMint';
import useAuthentication from '../../../../modules/useAuthentication';
import useErrors from '../../../../modules/useErrors';

import styles from './mint.module.scss';

const Mint: React.FC = () => {
  const { mintInfos } = useMintStore();
  const { addErrors } = useErrors();
  const { mint, isLoading } = useMint();
  const navigate = useNavigate();
  const { authenticate, isUserSignedIn } = useAuthentication();
  const quoteClass = classNames({
    [styles.info]: true,
    [styles.quote]: true,
  });

  const getMediaDisplay = useCallback(() => {
    const { mime, localURL } = mintInfos.file ?? {};
    const isImage = mime?.includes('image');
    const isAudio = mime?.includes('audio');
    const isVideo = mime?.includes('video');

    if (isImage)
      return <img className={styles.media} src={localURL} alt="User file" />;
    if (isVideo)
      return <video className={styles.media} src={localURL} controls />;
    if (isAudio)
      return <audio className={styles.media} src={localURL} controls />;
  }, [mintInfos]);

  const onSubmit = useCallback(
    async (event: FormEvent) => {
      try {
        event.preventDefault();

        if (!isUserSignedIn) await authenticate();

        await mint(mintInfos, (data: FinishedTxData) => {
          navigate(`/locked/${data.txId}`);
        });
      } catch (error) {
        throw new Error('Failed to mint');
      }
    },
    [authenticate, isUserSignedIn, mint, mintInfos, navigate]
  );

  useEffect(() => {
    addErrors({
      message: 'This is an error',
      type: 'error',
      delay: 2500,
    });
  }, []);

  return (
    <form onSubmit={onSubmit} className={styles.mint}>
      <header className={styles.header}>
        <Text
          tag="h1"
          color="dark"
          weight="semibold"
          variant="headline"
          lineheight="xxl"
          className="mb-md"
        >
          One last step
        </Text>
        <Text
          tag="p"
          variant="subtitle"
          lineheight="lg"
          align="center"
          color="dark"
        >
          Each lock mint costs{' '}
          <span style={{ color: 'var(--color-love)' }}>10 STX tokens</span>.{' '}
          <br />
          Make sure to have a bit more in order to cover transaction fees.
        </Text>
      </header>
      <div className={styles.receipt}>
        <div className={styles.info}>
          <Text
            tag="span"
            color="dark"
            variant="lg"
            lineheight="xl"
            className={styles.infoTitle}
          >
            Name 1 :
          </Text>
          <Text
            tag="span"
            variant="title"
            weight="semibold"
            lineheight="lg"
            color="love"
          >
            {mintInfos.nameOne}
          </Text>
        </div>
        <div className={styles.info}>
          <Text
            tag="span"
            variant="lg"
            lineheight="xl"
            color="dark"
            className={styles.infoTitle}
          >
            Name 2 :
          </Text>
          <Text
            tag="span"
            variant="title"
            weight="semibold"
            lineheight="lg"
            color="love"
          >
            {mintInfos.nameTwo}
          </Text>
        </div>
        <div className={quoteClass}>
          <Text
            tag="span"
            variant="lg"
            lineheight="xl"
            color="dark"
            className={styles.infoTitle}
          >
            Quote :
          </Text>
          <Text
            tag="span"
            variant="title"
            color="dark"
            lineheight="xl"
            align="center"
          >
            {mintInfos.quote}
          </Text>
        </div>
        <div className={quoteClass}>
          <Text
            tag="span"
            variant="lg"
            lineheight="xl"
            color="dark"
            className={styles.infoTitle}
          >
            File :
          </Text>
          {getMediaDisplay()}
        </div>
      </div>

      <footer className={styles.footer}>
        <Link to="/file">
          <Button variant="ghost">Back</Button>
        </Link>
        <Button isLoading={isLoading}>Mint my LoveLock</Button>
      </footer>

      <BackgroundImage />
    </form>
  );
};

export default Mint;
