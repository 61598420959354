import { FormEvent, useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { MAX_QUOTE_CHARACTERS } from '../../../../constants';

import useMintStore from '../../../../store/mint';

import Text from '../../../../components/text/text';
import Button from '../../../../components/button/button';
import BackgroundImage from '../../../../components/backgroundImage/backgroundImage';

import styles from './quote.module.scss';

const Quote: React.FC = () => {
  const navigate = useNavigate();
  const { mintInfos, setMintInfos } = useMintStore();
  const [charactersCounter, setCharactersCounter] = useState(
    mintInfos.quote?.length ?? MAX_QUOTE_CHARACTERS
  );

  const onInput = useCallback(
    (event: FormEvent<HTMLTextAreaElement>) => {
      const quote = event.currentTarget.value;

      setMintInfos({ ...mintInfos, quote });
      setCharactersCounter(quote.length);
    },
    [mintInfos, setMintInfos]
  );

  const onSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      navigate('/file');
    },
    [navigate]
  );

  return (
    <form onSubmit={onSubmit} className={styles.quote}>
      <header className={styles.header}>
        <h1 className={styles.heading}>
          <Text
            tag="span"
            weight="semibold"
            variant="headline"
            color="dark"
            lineheight="xxl"
          >
            Hi
          </Text>
          <Text
            tag="span"
            color="love"
            weight="semibold"
            variant="headline"
            lineheight="xxl"
            className="mx-sm"
          >
            {mintInfos.nameOne}
          </Text>
          <Text
            tag="span"
            weight="semibold"
            color="dark"
            variant="headline"
            lineheight="xxl"
          >
            and
          </Text>
          <Text
            tag="span"
            color="love"
            variant="headline"
            weight="semibold"
            lineheight="xxl"
            className="ml-sm"
          >
            {mintInfos.nameTwo}
          </Text>
          <Text
            tag="span"
            weight="semibold"
            variant="headline"
            color="dark"
            lineheight="xxl"
          >
            .
          </Text>
        </h1>
        <Text
          tag="h2"
          weight="semibold"
          variant="headline"
          color="dark"
          lineheight="xxl"
        >
          You can add a short quote
        </Text>
        <Text
          tag="p"
          color="dark"
          variant="subtitle"
          align="center"
          lineheight="lg"
          className="mt-md"
        >
          Love lock is unalterable, create your lock and you will have the
          certainty to keep engraved forever the union with the person you love,
          Each lock gives the possibility to store 2 names and a document, think
          carefully because it can not be modified once created.
        </Text>
      </header>

      <div className={styles.quoteInputWrapper}>
        <textarea
          value={mintInfos.quote}
          onInput={onInput}
          maxLength={MAX_QUOTE_CHARACTERS}
          rows={5}
          className={styles.quoteInput}
        />
        <Text
          align="right"
          color="dark"
          weight="bold"
        >{`${charactersCounter} / ${MAX_QUOTE_CHARACTERS}`}</Text>
      </div>

      <footer>
        <Link to="/" className="mr-sm">
          <Button variant="ghost">Back</Button>
        </Link>
        <Button>Continue</Button>
      </footer>

      <BackgroundImage />
    </form>
  );
};

export default Quote;
