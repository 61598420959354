import useAuthentication from './useAuthentication';

const useFileEncryption = () => {
  const { userSession } = useAuthentication();

  const encryptFile = async (file: File) => {
    const { appPrivateKey } = userSession.loadUserData();

    try {
      const buffer = Buffer.from(await file.arrayBuffer());
      const encryptedFile = await userSession.encryptContent(buffer, {
        privateKey: appPrivateKey,
      });

      return encryptedFile;
    } catch (error) {
      throw new Error('Failed to encrypt file');
    }
  };

  const decryptFile = async (buffer: Buffer) => {
    const { appPrivateKey } = userSession.loadUserData();

    try {
      const decryptedFile = await userSession.decryptContent(
        buffer.toString(),
        {
          privateKey: appPrivateKey,
        }
      );

      return decryptedFile;
    } catch (error) {
      throw new Error('Failed to decrypt file');
    }
  };

  return {
    encryptFile,
    decryptFile,
  };
};

export default useFileEncryption;
