import { FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import { MAX_NAME_CHARACTERS } from '../../../../constants';

import useMintStore from '../../../../store/mint';

import Button from '../../../../components/button/button';
import Text from '../../../../components/text/text';
import FieldInput from '../../../../components/fieldInput/fieldInput';

import styles from './hero.module.scss';

const Hero: React.FC = () => {
  const navigate = useNavigate();
  const { mintInfos, setMintInfos } = useMintStore();

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    navigate('/quote');
  };

  return (
    <section className={styles.hero}>
      <header>
        <Text
          tag="h1"
          variant="headline"
          weight="semibold"
          color="dark"
          lineheight="xxl"
        >
          The timeless proof of your love
        </Text>
      </header>

      <form onSubmit={onSubmit} className={styles.form}>
        <FieldInput
          value={mintInfos.nameOne}
          onInput={(event) =>
            setMintInfos({ ...mintInfos, nameOne: event.target.value })
          }
          label="Lover One"
          id="lover-one"
          maxLength={MAX_NAME_CHARACTERS}
          required
        />
        <FieldInput
          value={mintInfos.nameTwo}
          onInput={(event) =>
            setMintInfos({ ...mintInfos, nameTwo: event.target.value })
          }
          label="Lover Two"
          id="lover-two"
          maxLength={MAX_NAME_CHARACTERS}
          required
        />
        <Button type="submit">Lock my love</Button>
      </form>
    </section>
  );
};

export default Hero;
